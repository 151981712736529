import React from "react";
import "./styles.css";

import Header from "../../components/header";
import Footer from "../../components/footer";

const Rezultati = () => {
  return (
    <div className="page-container">
      <Header />

      <h2 className="hero-section-heading mb-16 mt-100">REZULTATI</h2>

      <div className="rezultati-div animate__animated animate__fadeInUp">
        <div className="rezultati-content">
          {/* Evropsko */}
          <h2 className="rezultati-h">EVROPSKO PRVENSTVO</h2>

          <p className="rezultati-p">
            Vuk Vučinić – 1. mjesto, kumite -63kg, katet, Tbilisi, februar 2024;
          </p>

          <p className="rezultati-p">
            Balša Vojinović – 3. mjesto, kumite -61kg, junior, Tbilisi, februar
            2024;
          </p>

          <p className="rezultati-p">
            Anes Arifaj – 3. mjesto, kumite -70kg, kadet, Tbilisi, februar 2024;
          </p>

          <p className="rezultati-p">
            Balša Vojinović – 2. mjesto, kumite -55kg, junior, Prag, jul 2022;
          </p>

          {/* Svjetski kup */}

          <h2 className="rezultati-h">SVJETSKI LIGA MLADIH, SVJETSKI KUP</h2>

          <p className="rezultati-p">
            Balša Vojinović – 3.mjesto, mladji senior – 60 kg, Poreč (Hrvatska),
            jul 2024
          </p>

          <p className="rezultati-p">
            Vuk Vučinić – 3.mjesto, kadet – 70 kg, Poreč (Hrvatska), jul 2024
          </p>

          <p className="rezultati-p">
            Katarina Vlahović – 3.mjesto, kadet – kate, Poreč (Hrvatska), jul
            2024
          </p>

          <p className="rezultati-p">
            Vuk Vučinić – 3.mjesto, kadet -63 kg, A Coruna (Španija), maj 2024;
          </p>

          <p className="rezultati-p">
            Katarina Djukić – 3.mjesto, kadet -54 kg, A Coruna (Španija), maj
            2024;
          </p>

          <p className="rezultati-p">
            Anes Arifaj – 3. mjesto, kumite, kadeti, -70kg, Fudžaira, februar
            2024;
          </p>
          <p className="rezultati-p">
            Saša Petrić – 1. mjesto, kate U14, Poreč, jun 2022;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – 3. mjesto, kumite –55kg, junior, Poreč, jun 2022;
          </p>
          <p className="rezultati-p">
            Saša Petrić - 3. mjesto, kate U14, Venecija, decembar 2021;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 3. mjesto, kumite U14 -70kg, Venecija, decembar 2021;
          </p>
          <p className="rezultati-p">
            Saša Petrić - 3. mjesto, kate U14, Istanbul, oktobar 2021;
          </p>
          <p className="rezultati-p">
            Saša Petrić - 3. mjesto, kate U14, Poreč, jun 2021;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – 1. mjesto, juniori, 55kg, La Corunja, april 2023;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 1. mjesto, kadeti –70kg, Poreč, jul 2023;
          </p>

          {/* Evropsko malih zemalja */}

          <h2 className="rezultati-h">
            EVROPSKO PRVENSTVO MALIH ZEMALJA - WKF
          </h2>

          <p className="rezultati-p">
            Martina Todorović – 1. mjesto, kumite, U14 -42kg, Sarajevo, novembar
            2024;
          </p>
          <p className="rezultati-p">
            Katarina Šušković – 2. mjesto, kumite, U14 -42kg, Sarajevo, novembar
            2024;
          </p>
          <p className="rezultati-p">
            Dunja Vratnica – 2. mjesto, kumite, U14 +52kg, Sarajevo, novembar
            2024;
          </p>
          <p className="rezultati-p">
            Lana Prelević – 2. mjesto, kate, U14, Sarajevo, novembar 2024;
          </p>
          <p className="rezultati-p">
            Nikola Stanišić – 3. mjesto, kate, kadeti, Sarajevo, novembar 2024;
          </p>
          <p className="rezultati-p">
            Lana Prelević – 1. mjesto, kate ekipno, U14, Sarajevo, novembar
            2024;
          </p>
          <p className="rezultati-p">
            Martina Todorović, Katarina Šušković, Dunja Vratnica – 3. mjesto,
            kumite ekipno, U14, Sarajevo, novembar 2024;
          </p>

          <p className="rezultati-p">
            Saša Petrić – 1. mjesto, kate, kadeti, Vaduz, septembar 2022;
          </p>
          <p className="rezultati-p">
            Saša Petrić – 1. mjesto, kate U14, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Aleksa Vujošević – 2. mjesto, kate U14, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Katarina Vlahović – 3. mjesto, kate U14, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Nikola Stanišić – 3. mjesto, kate U12, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – 1. mjesto, kumite kadet -52kg, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Stefan Miljanič – 1. mjesto, stariji pionir, kumite, Podgorica,
            2021;
          </p>
          <p className="rezultati-p">
            Vuk Vučinić – 1. mjesto, stariji pionir, kumite, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 2. mjesto, stariji pionir, kumite, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Anes Arifaj, Stefan Miljanić – 2. mjesto, ekipno kumite, Podgorica,
            2021;
          </p>
          <p className="rezultati-p">
            Petar Radovanović – 3. mjesto, kadet kumite, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Filip Šoškić – 3. mjesto, kumite U14, Podgorica, 2021;
          </p>
          <p className="rezultati-p">
            Lazović Branko, Šoškić Filip, Vučinić Vuk, - 3. mjesto, ekipno
            kumite, Podgorica, 2021;
          </p>

          {/* Balkansko */}

          <h2 className="rezultati-h">BALKANSKA PRVENSTVA</h2>

          <p className="rezultati-p">
            Balša Vojinović – kumite U21, -61 kg, Sarajevo – Novembar 2024;
          </p>
          <p className="rezultati-p">
            Katarina Vlahović – kate, juniorke – Novembar 2024;
          </p>
          <p className="rezultati-p">
            Viktorija Raičević i Katarina Vlahović – kate ekipno, juniorke –
            Novembar 2024;
          </p>
          <p className="rezultati-p">
            Nikša Zejak, Anes Arifaj i Vuk Vučinić – kumite ekipno, juniori –
            Novembar 2024;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – kumite ekipno, mlađi seniori – Novembar 2024;
          </p>

          <p className="rezultati-p">
            Martina Todorović - 3.mjesto, U14 –35 kg; Istanbul (Turska), Maj
            2024
          </p>

          <p className="rezultati-p">
            Anes Arifaj – 2. mjesto, mladji pionir 2008. god, 40kg, Beograd, jun
            2018;
          </p>
          <p className="rezultati-p">
            Vuk Vučinić – 2.mjesto, mladji pionir 2008.god, 45 kg, Beograd, jun
            2018;
          </p>
          <p className="rezultati-p">
            Tamara Klisić – 2.mjesto, stariji pionir 2008. god, Banja Luka, jun
            2019;
          </p>
          <p className="rezultati-p">
            Kata tim – Klisić Tamara, Vlahović Katarina, Raičević Viktorija,
            stariji pioniri 2008, Banja Luka, jun 2019;
          </p>
          <p className="rezultati-p">
            Stefan Miljanić – 1. mjesto, stariji pionir 2008. god, 50kg, Banja
            Luka, jun 2019;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 3. mjesto, stariji pionir 2008. god, 45kg, Banja Luka,
            jun 2019;
          </p>
          <p className="rezultati-p">
            Viktorija Vukadinović – 3. mjesto, stariji pionir 2008. god, 40kg,
            Banja Luka, jun 2019;
          </p>
          <p className="rezultati-p">
            Denis Huremović – 3 sa muškom ekipom U21 u borbama BKF, Dubrovnik,
            decembar;
          </p>
          <p className="rezultati-p">
            Katarina Vlahović – 2. mjesto, kate 2008. god, Poreč novembar 2021;
          </p>
          <p className="rezultati-p">
            Tamara Klisić - 3. mjesto kate 2008. god, Poreč novembar 2021;
          </p>
          <p className="rezultati-p">
            Saša Petrič – 1. mjesto, kate 2008. god, Poreč, novembar 2021;
          </p>
          <p className="rezultati-p">
            Kata team Tamara Klisić, Katarina Vlahović i Viktorija Raičević – 2.
            mjesto, kate 2008. god, Poreč novembar 2021;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 1. mjesto, kumite U14 + 55kg, Poreč, novembar 2021;
          </p>
          <p className="rezultati-p">
            Vuk Vučinić – 3. mjesto, kumite U14, 55kg, Poreč, novembar 2021;
          </p>
          <p className="rezultati-p">
            Filip Šoškić – 3.mjesto, kumite U14 55kg, Poreč, novembar 2021;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – 3. mjesto Junior -55kg, Rijeka, novembar 2021;
          </p>
          <p className="rezultati-p">
            Igor Dijanič – 2. mjesto, kumite veteran, Beograd, jun 2022;
          </p>
          <p className="rezultati-p">
            Lana Prelević – 2. mjesto, kate U12, Beograd, jun 2022;
          </p>
          <p className="rezultati-p">
            Anes Arifaj – 1. mjesto, kadet -70kg, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Branko Lazović – 2. mjesto, kadet -52kg, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Kata team kadetkinje – Katarina Vlahović, Tamara Klisić, Viktorija
            Raičević, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Filip Šoškić – 3. mjesto, 57kg kadet, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Vuk Vučinić – 3. mjesto – 57kg, kadet, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Saša Petrić - 3. mjesto, kate, kadet, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Katarina Vlahović - 3. mjesto, kate, kadet, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Balša Vojinović - 3. mjesto –55kg, junior, Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Anes Arifaj, Filip Šoškič – 3. mjesto sa kadetskom ekipom u borbama,
            Bar, Novembar 2022;
          </p>
          <p className="rezultati-p">
            Balša Vojinović – 3. mjesto sa juniorskom ekipom u borbama, Bar,
            Novembar 2022;
          </p>
          <p className="rezultati-p">
            Igor Dijanić – 1.mjesto, kumite veteran – Tirana, Septembar
            2023.godine;
          </p>
          <p className="rezultati-p">
            Saša Petrić – 2.mjesto, kate kadeti – Ljubljana, Novembar
            2023.godine;
          </p>
          <p className="rezultati-p">
            Stefan Miljanić - 2.mjesto, -70 kadeti – Ljubljana, Novembar
            2023.godine;
          </p>
          <p className="rezultati-p">
            Anes Arifaj - 3.mjesto, -70 kadeti – Ljubljana, Novembar
            2023.godine;
          </p>
          <p className="rezultati-p">
            Vuk Vučinić - 3.mjesto, -63 kadeti – Ljubljana, Novembar
            2023.godine;
          </p>
          <p className="rezultati-p">
            A. Arifaj, V.Vučinić, F.Šoškić, S.Miljanić – 2.mjesto, kumite kadeti
            ekipno - Ljubljana, Novembar 2023.godine;
          </p>
          <p className="rezultati-p">
            Igor Dijanić – 3.mjesto – kumite, veteran +85 kg – Podgorica,
            februar 2024.godine;
          </p>

          <h2 className="rezultati-h">MEDITERANSKO PRVENSTVO</h2>
          <p className="rezultati-p">
            Balša Vojinović - 3.mjesto, 55kg, junior, Tunis, maj, 2023;
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Rezultati;
